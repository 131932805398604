
export const initLazyLoad = (snippet, lazyLoadButtonId, lazyLoadPlaceholderId) => {
    initLoadProductsButtonElementClick(lazyLoadButtonId, lazyLoadPlaceholderId);

    $.nette.ext({
        complete: function (xhr) {
            if (!xhr.snippets) {
                return;
            }

            const hasFavouriteProducstLazyLoadingCompleted = Object.keys(xhr.snippets).includes(snippet);

            if (hasFavouriteProducstLazyLoadingCompleted) {
                hidePlaceholder(lazyLoadPlaceholderId);
                initLoadProductsButtonElementClick(lazyLoadButtonId, lazyLoadPlaceholderId);
            }
        }
    });
};

const initLoadProductsButtonElementClick = (lazyLoadButtonId, lazyLoadPlaceholderId) => {
    const loadProductsButtonElement = document.getElementById(lazyLoadButtonId);

    if (!loadProductsButtonElement) return;

    loadProductsButtonElement.onclick = () => {
        showPlaceholder(lazyLoadPlaceholderId, 'block');
    }
}

export const showPlaceholder = (lazyLoadPlaceholderId, displayStyle) => {
    const placeholderElement = document.getElementById(lazyLoadPlaceholderId);

    placeholderElement.style.display = displayStyle;
}

export const hidePlaceholder = (lazyLoadPlaceholderId) => {
    const placeholderElement = document.getElementById(lazyLoadPlaceholderId);

    placeholderElement.style.display = 'none';
}
